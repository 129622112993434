<template>
  <div>
    <div v-if="currencyCodes">
      <v-row no-gutters justify="space-between" align="center">
        <v-btn large depressed tile @click="addCurrencyCode">
          <v-icon left>mdi-application-outline </v-icon>
          Add currency code
        </v-btn>
        <v-col cols="4">
          <search-text-field v-model="search" />
        </v-col>
      </v-row>
      <v-data-table @click:row="showDetails" :headers="headers" :items="populatedCodes" :search="search" v-bind="$util.dataTableAttributes()">
        <template #[`item.name`]="{ item }">
          <router-link :to="{ name: 'CurrencyCodeDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
        </template>
      </v-data-table>
    </div>
    <create-edit-currency-code ref="CreateEditCode" @item-updated="getCurrencyCodes" />
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';
import CreateEditCurrencyCode from './Dialogs/CreateEditCurrencyCode.vue';

export default {
  name: 'CurrencyCodeList',
  components: { CreateEditCurrencyCode },
  data: () => ({
    currencyCodes: [],
    priceLists: [],
    isLoading: false,
    search: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        ...this.priceLists.map((list, index) => ({
          text: list.name,
          value: `lists[${index}]`,
        })),
      ];
    },
    populatedCodes() {
      return this.currencyCodes.map((code) => ({
        ...code,
        lists: this.priceLists.map((list) => list.values.find((val) => val.currencyCodeId === code.id)?.priceFactor),
      }));
    },
  },
  watch: {
    '$route.name'(next) {
      if (next === 'CurrencyCodeList') {
        this.getCurrencyCodes();
      }
    }
  },
  methods: {
    async getCurrencyCodes() {
      this.isLoading = true;
      this.currencyCodes = await apiService.getCurrencyCodes();
      this.priceLists = await apiService.getPriceLists();
      this.isLoading = false;
    },
    addCurrencyCode() {
      this.$refs.CreateEditCode.addItem();
    },
    showDetails(currencyCode) {
      this.$router.push({ name: 'CurrencyCodeDetails', params: { Id: currencyCode.id } });
    },
  },
  created() {
    this.getCurrencyCodes();
  },
};
</script>